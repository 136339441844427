.svg-container {
    width: 100%;
    height: 100%;
  }
  
  .letter {
    opacity: 0;
    transform: translateY(30px);
    animation: fadeInUp 0.5s forwards;
  }
  
  .letter-1 { animation-delay: 0s; }
  .letter-2 { animation-delay: 0.2s; }
  .letter-3 { animation-delay: 0.4s; }
  .letter-4 { animation-delay: 0.6s; }
  .letter-5 { animation-delay: 0.8s; }
  .letter-6 { animation-delay: 1s; }
  .letter-7 { animation-delay: 1.2s; }
  .letter-8 { animation-delay: 1.4s; }
  
  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  