//@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');
@import url('./fontawesome/css/fontawesome.css');
@import url('./fontawesome/css/brands.css');
@import url('./fontawesome/css/solid.css');

/* Theme style (colors & sizes) */
@import "theme-default";

/* Core Libs & Lib configs */
@import "libs/all";

/* Mixins */
@import "mixins";

/* Theme components */
@import "nav-bar";
@import "aside";
@import "title-bar";
@import "hero-bar";
@import "card";
@import "table";
@import "tiles";
@import "form";
@import "main-section";
@import "modal";
@import "footer";
@import "misc";



body {
    margin: 0;
    font-family: 'Nunito', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html, body { height: 100vh; }

.loading-container svg {
    width: 150px;
    height: 150px;
    margin: 20px;
    display: inline-block;
    stroke: green;
}


@media screen and (min-width: 769px), print{
  .field-body > .field:not(.is-narrow) {box-flex: 1;  flex-grow: 1;}
  .field-body>.field{ flex:1;}
}

.card {
  margin-bottom: 10px;
  width: 100%;
  
  .card-header {
    cursor: pointer;
  
    .icon {
      transition: transform 150ms ease-out;
    }
  }
      
  .card-content {
    transition: max-height 250ms ease;
    overflow: hidden;
  }
}
      
.card.not-expanded {
  .card-header {
    .icon {
      transform: rotate(0deg);
    }
  }
  
  .card-content {
    padding: 0;
  }
}

/*Ajustes para panel de seleccion de emojis*/
.cover-emoji-left {
  position: fixed;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-70%, -50%);
}

.cover-emoji-right {
  position: fixed;
  z-index: 1;
  left: 60%;
  top: 50%;
  transform: translate(-30%, -50%);
}

/*Newsletter preview*/

.contenedor-imagen {
  position: relative;
  display: inline-block;
  width: 100px; 
  height: 100px; 
  overflow: hidden; 
}

.imagen-con-preview {
  width: 100%; 
  height: auto;
  display: block;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%); 
}

.modal2 {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Evita eventos de ratón en el modal */
  opacity: 0;
  transition: opacity 0.3s ease; 
}

.modal2 img {
  width: 100%; /* Ancho máximo de la imagen dentro del modal */
  height: auto; /* Altura máxima de la imagen dentro del modal */
  display: block;
}

.contenedor-imagen:hover .modal2 {
  pointer-events: auto; /* Activa eventos de ratón en el modal al pasar el mouse sobre la imagen */
  opacity: 1;
}

.phone-frame {
  position: relative;
  width: 320px;
  height: 640px; /* Ajusta la altura para incluir el marco */
  margin: 0 auto;
  background-image: url('../assets/img/phone.png'); /* Ruta a tu imagen de marco */
  background-size: cover;
  background-position: center;
}

.phone-content {
  position: absolute;
  top: 10px; /* Ajusta la posición del contenido dentro del marco */
  margin: 10px;
  padding: 30px; /* Añade relleno según sea necesario */
  overflow-y: auto; /* Agrega un scroll si el contenido es grande */
}

.message-content {
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #ffffff;
}

.message-bubble {
  background-color: #22ade4;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sender-info {
  font-size: 12px;
  color: #777;
  margin-top: 10px;
}

.whatsapp-bubble {
  background-color: #DCEDC8;
  border-radius: 10px;
  color: black !important;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.whatsapp-bubble p {
  text-align: justify;
}