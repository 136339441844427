nav.navbar {
  box-shadow: $navbar-box-shadow;

  .navbar-item {
    &.has-user-avatar {
      .is-user-avatar {
        margin-right: $default-padding * .5;
        display: inline-flex;
        width: $navbar-avatar-size;
        height: $navbar-avatar-size;
      }
    }

    &.has-divider {
      border-right: $navbar-divider-border;
    }

    &.no-left-space {
      padding-left: 0;
    }

    &.has-dropdown {
      padding-right: 0;
      padding-left: 0;

      .navbar-link {
        padding-right: $navbar-item-h-padding;
        padding-left: $navbar-item-h-padding;
      }
    }

    &.has-control {
      padding-top: 0;
      padding-bottom: 0;
    }

    .control {
      .input {
        color: $navbar-input-color;
        border: 0;
        box-shadow: none;
        background: transparent;

        &::placeholder {
          color: $navbar-input-placeholder-color;
        }
      }
    }
  }
}

@include touch {
  nav.navbar {
    display: flex;
    padding-right: 0;

    .navbar-brand {
      flex: 1;

      &.is-right {
        flex: none;
      }
    }

    .navbar-item {
      &.no-left-space-touch {
        padding-left: 0;
      }
    }

    .navbar-menu {
      position: absolute;
      width: 100vw;
      padding-top: 0;
      top: $navbar-height;
      left: 0;

      .navbar-item {
        .icon:first-child {
          margin-right: $default-padding * .5;
        }

        &.has-dropdown {
          >.navbar-link {
            background-color: $white-ter;
            .icon:last-child {
              display: none;
            }
          }
        }

        &.has-user-avatar {
          >.navbar-link {
            display: flex;
            align-items: center;
            padding-top: $default-padding * .5;
            padding-bottom: $default-padding * .5;
          }
        }
      }
    }
  }
}

@include desktop {
  nav.navbar {
    .navbar-item {
      padding-right: $navbar-item-h-padding;
      padding-left: $navbar-item-h-padding;

      &:not(.is-desktop-icon-only) {
        .icon:first-child {
          margin-right: $default-padding * .5;
        }
      }
      &.is-desktop-icon-only {
        span:not(.icon) {
          display: none;
        }
      }
    }
  }
}
