:root {
    --brandColor: hsl(166, 67%, 51%);
    --background: rgb(247, 247, 247);
    --textDark: hsla(0, 0%, 0%, 0.66);
    --textLight: hsla(0, 0%, 0%, 0.33);
  }

  body {
    background: var(--background);
    height: 100vh;
    color: var(--textDark);
  }

  .field:not(:last-child) {
    margin-bottom: 1rem;
  }

  .register {
    margin-top: 10rem;
    background: white;
    border-radius: 10px;
  }

  .left,
  .right {
    padding: 1rem;
  }

  .left {
    border-right: 5px solid var(--background);
  }

  .left .title {
    font-weight: 800;
    letter-spacing: -2px;
  }

  .left .colored {
    color: var(--brandColor);
    font-weight: 500;
    margin-top: 1rem !important;
    letter-spacing: -1px;
  }

  .left p {
    color: var(--textLight);
    font-size: 1.15rem;
  }

  .right .title {
    font-weight: 800;
    letter-spacing: -1px;
  }

  .right .description {
    margin-top: 1rem;
    margin-bottom: 1rem !important;
    color: var(--textLight);
    font-size: 1.15rem;
  }

  .right small {
    color: var(--textLight);
  }

  input {
    font-size: 1rem;
  }

  input:focus {
    border-color: var(--brandColor) !important;
    box-shadow: 0 0 0 1px var(--brandColor) !important;
  }

  /*.fab,
  .fas {
    color: var(--textLight);
    margin-right: 1rem;
  }*/

  @media only screen and (max-width: 600px) {
    /* Ajusta el contenedor para dispositivos móviles */
    div[style="display: flex; height: 500px; width: 100%;"] {
        height: auto;
        max-height: 500px; /* Establece una altura máxima para el contenedor */
    }
}