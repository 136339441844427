.body-custom {
    height: 100vh;
    width: 100%;
    background: rgb(70,162,198);
    background: linear-gradient(90deg, rgba(70,162,198,1) 0%, rgba(47,83,203,1) 100%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    text-align: center;
    padding: 2rem;
  }

  .logo-brand {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: #fff;
  }
  .logo-brand img {
    height: 40px;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
  .box img {
    height: 50px;
    margin-bottom: 1rem;
  }
  .bottom-links {
    margin-top: 1rem;
  }