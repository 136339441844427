footer.footer {
  .logo {
    img {
      width: auto;
      height: $footer-logo-height;
    }
  }
}

@include mobile {
  .footer-copyright {
    text-align: center;
  }
}
