.kvd-bg{
    background: linear-gradient(to right, #ffffff 80%, #f1f1f1 80%)
}

.login-form{
    width: 60%;
}


/* Small devices such as large phones (640px and up) */
@media only screen and (min-width: 40em) {
    .login-form{
        width: 80%;
    }
}

/* Medium devices such as tablets (768px and up) */
@media only screen and (min-width: 48em) {
    .login-form{
        width: 80%;
    }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 64em) {
    .login-form{
        width: 60%;
    }
}

/* Largest devices such as desktops (1280px and up) */
@media only screen and (min-width: 80em) {
    .login-form{
        width: 60%;
    }
}