.stars {
    cursor: pointer;
  }
  
  .fa-star {
    font-size: 2rem;
    margin: 0.2rem;
  }
  
  .far {
    color: #d3d3d3;
  }
  
  .fas {
    color: #ffd700;
  }
  