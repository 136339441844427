@import "../../scss/fontawesome/css/fontawesome.css";

.container-email-editor {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}

/* overlay styles */

.image-item {
    position: relative;
    cursor: pointer;
    border: 1px solid rgb(187, 185, 185);
    border-radius: 5px;
    min-height: 350px;
}


.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.811);
    top: 0;
    left: 0;
    transform: scale(0);
    transition: all 0.2s 0.1s ease-in-out;
    color: #000000;
    /* center overlay content */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

/* hover */
.image-item:hover .overlay {
    transform: scale(1);
}
